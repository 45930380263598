import type { IContactBase } from '@/interfaces/IContactBase';
import { ContactFormSchema } from '@/schemas';
import { NForm, NFormItemGi, NGrid, NInput, NInputGroup, NSelect, type FormRules } from 'naive-ui';
import { defineComponent, ref } from 'vue';
import { AppInputPhone } from '../Inputs';
import { defineForm } from './FormBase';

export const ContactForm = defineComponent({
	name: 'ContactForm',
	extends: defineForm<IContactBase, FormRules>({ schema: ContactFormSchema }),
	setup() {
		return {
			phoneType: ref<'cel' | 'tel'>('cel'),
		};
	},

	render() {
		return (
			<NForm
				size="large"
				ref="formRef"
				model={this.formData}
				rules={this.formSchema}
				label-placement="top"
			>
				<NGrid cols="1 400:2" x-gap="4" y-gap="4">
					<NFormItemGi label="Email" path="email">
						<NInput placeholder="exemplo@email.com" v-model:value={this.formData.email} />
					</NFormItemGi>
					<NFormItemGi label="Telefone" path="phone">
						<NInputGroup>
							<NSelect
								style="width: 20%"
								v-model:value={this.phoneType}
								options={[
									{ label: 'Celular', value: 'cel' },
									{ label: 'Telefone', value: 'tel' },
								]}
							/>
							<AppInputPhone phoneType={this.phoneType} v-model={this.formData.phone} />
						</NInputGroup>
					</NFormItemGi>
				</NGrid>
			</NForm>
		);
	},
});

export default ContactForm;
