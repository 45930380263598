import type { IResponseBase } from '@/interfaces';
import Http from '@/services/http';
import type { ClientResponse, ClientRequest } from './Client.dto';

const http = Http.create();

export class ClientService {
	static async getClient(clientId: string) {
		const response = await http.get<IResponseBase<ClientResponse>>(`/clients/${clientId}`);
		return response.data;
	}

	static async updateClient(clientId: string, client: ClientRequest) {
		const response = await http.patch<IResponseBase<ClientResponse>>(
			`/clients/${clientId}`,
			client,
		);
		return response.data;
	}

	static async createClient(client: ClientRequest) {
		const response = await http.post<IResponseBase<ClientResponse>>(`/clients`, client);
		return response.data;
	}
}
