import { defineForm } from '@/components/Forms/FormBase';
import { defineComponent, nextTick } from 'vue';
import type { ClientFormData } from './Client.dto';
import {
	NForm,
	NFormItem,
	NFormItemGi,
	NGrid,
	NInput,
	NRadio,
	NRadioGroup,
	NSelect,
} from 'naive-ui';
import { AppInputCpf, AppInputDate, AppInputMoney, AppInputCnpj } from '@/components';
import { ContactForm } from '@/components/Forms';
import { PersonType } from '@/constants';
import { PublicService } from '@/services/publicService';

const ClientFormSchema = {};

export const ClientForm = defineComponent({
	name: 'ClientForm',

	extends: defineForm<ClientFormData>(),

	methods: {
		async searchRenter() {
			var response = await PublicService.getRenter(this.formData.document);
			if (response.data) {
				nextTick(() => {
					this.formData = response.data;
				});
			}
		},
	},

	render() {
		return (
			<NForm
				size="large"
				ref="formRef"
				{...{ attrs: this.$attrs }}
				label-placement="top"
				rules={ClientFormSchema}
				model={this.formData}
				onSubmit={this.handleSubmit}
			>
				<div class="mt-2">
					<NFormItem label="Tipo de pessoa" path="personType">
						<NRadioGroup v-model:value={this.formData.personType}>
							<NRadio label="Pessoa física" value={PersonType.Natural} />
							<NRadio label="Pessoa jurídica" value={PersonType.LegalPerson} />
						</NRadioGroup>
					</NFormItem>

					<NGrid cols="1 400:2" x-gap="4" y-gap="4">
						<NFormItemGi label="CPF" path="document">
							<AppInputCpf
								data-cy="input-document"
								onChange={this.searchRenter}
								v-model={this.formData.document}
							/>
						</NFormItemGi>

						<NFormItemGi label="Nome completo" path="name">
							<NInput
								data-cy="input-name"
								v-model:value={this.formData.name}
								placeholder="Nome Completo"
							/>
						</NFormItemGi>

						<NFormItemGi label="Data de nascimento" path="birthDate">
							<AppInputDate data-cy="input-birthDate" v-model={this.formData.birthDate} />
						</NFormItemGi>

						<NFormItemGi label="Renda declarada" path="declaredIncome">
							<AppInputMoney
								data-cy="input-declaredIncome"
								v-model={this.formData.declaredIncome}
							/>
						</NFormItemGi>

						<NFormItemGi label="Ocupação" path="occupation">
							<NSelect
								v-model:value={this.formData.occupation}
								options={[
									{ label: 'Assalariado', value: 'Assalariado' },
									{
										label: 'Autonomo/Profissional liberal',
										value: 'Autonomo/Profissional liberal',
									},
									{ label: 'Empresário', value: 'Empresário' },
									{ label: 'Aposentado', value: 'Aposentado' },
								]}
							/>
						</NFormItemGi>

						{this.formData.personType == PersonType.LegalPerson && [
							<NFormItemGi label="Data de abertura" path="openingDate">
								<AppInputDate data-cy="input-openingDate" v-model={this.formData.openingDate} />
							</NFormItemGi>,
							<NFormItemGi label="CNPJ" path="cnpj">
								<AppInputCnpj data-cy="input-cnpj" v-model={this.formData.cnpj} />
							</NFormItemGi>,
							<NFormItemGi label="Razão Social" path="fantasyName">
								<NInput
									data-cy="input-name"
									v-model:value={this.formData.fantasyName}
									placeholder="Razão Social"
								/>
							</NFormItemGi>,
						]}
					</NGrid>

					<ContactForm ref="formContactRef" v-model={this.formData.contact} />
				</div>
			</NForm>
		);
	},
});
