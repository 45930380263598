import Colors from '@/utils/colors';
import { globalTheme } from './globalTheme';
export const darkThemeOverrides = {
	...globalTheme,
	common: {
		primaryColor: Colors.primaryColor,
		primaryColorHover: Colors.primaryColor,
	},
	// Layout: {
	// 	color: Colors.mainBackgroundColor,
	// 	headerColor: Colors.softBackground,
	// 	footerColor: Colors.mainBackgroundColor,
	// },
	// DataTable: {
	// 	borderColor: Colors.strokeColor,
	// 	thColor: Colors.mainBackgroundColor,
	// 	tdColor: Colors.mainBackgroundColor,
	// 	tdColorHover: Colors.strokeColor,
	// },
	// Table: {
	// 	borderColor: Colors.strokeColor,
	// 	thColor: Colors.mainBackgroundColor,
	// 	tdColor: Colors.mainBackgroundColor,
	// 	tdColorHover: Colors.strokeColor,
	// 	tdColorStriped: '#000',
	// },
	// Card: {
	// 	color: Colors.softBackground,
	// 	borderColor: Colors.strokeColor,
	// },
	// List: {
	// 	color: Colors.mainBackgroundColor,
	// 	borderColor: Colors.strokeColor,
	// },
	// Descriptions: {
	// 	thFontWeight: 'bold',
	// 	borderColor: Colors.mainBackgroundColor,
	// },
};
