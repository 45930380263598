import { RenderIcon } from '@/components';
import type { Routes } from '@/utils';
import { NTag, type MenuOption } from 'naive-ui';
import { h, type Component } from 'vue';
import { RouterLink } from 'vue-router';

export class MenuItem {
	name: string;
	router: string;
	icon: Component | null;
	children: Array<MenuItem> | null;

	constructor(
		name: string,
		routerName: string,
		icon: Component | null = null,
		children: Array<MenuItem> | null = null,
	) {
		this.name = name;
		this.router = routerName;
		this.icon = icon;
		this.children = children;
	}

	static create(
		name: string,
		routerName: Routes | null,
		icon: Component | null = null,
		children: Array<MenuOption> | null = null,
		params: Record<string, string> = {},
		meta: Record<string, string> = {},
	) {
		let menu: MenuOption = {
			key: routerName || name,
			label: () =>
				routerName
					? h(
							RouterLink,
							//@ts-ignore
							{
								to: { name: routerName, params: params, meta: meta },
							},
							{ default: () => h('span', name) },
					  )
					: h('span', name),
		};

		if (children) menu = { ...menu, children };
		if (icon) menu = { ...menu, icon: RenderIcon(icon) };

		return menu;
	}
}

export default MenuItem;
