import type { IViaCepResponse } from '@/interfaces';
import { StringMask } from '@/utils/StringMask';
import axios from 'axios';
import { NInput } from 'naive-ui';
import { defineComponent, ref } from 'vue';

const MAX_CEP_LENGTH = 8;

export const AppInputCep = defineComponent({
	emits: ['update:modelValue', 'address'],

	props: {
		modelValue: String,
	},

	setup(props, { emit }) {
		const http = axios.create({ baseURL: 'https://viacep.com.br/ws' });
		const cepMask = new StringMask({ mask: '00000-000' });

		const maskedValue = ref(
			props.modelValue ? cepMask.apply(props.modelValue.toString()).maskedValue : '',
		);
		const loading = ref(false);

		async function getAddress(cep: number) {
			try {
				loading.value = true;
				const { data } = await http.get<IViaCepResponse>(
					`/${cep.toString().padStart(8, '0')}/json`,
				);
				emit('address', data);
			} catch {
			} finally {
				loading.value = false;
			}
		}

		function handleInput(value: string) {
			const response = cepMask.apply(value);
			const parsedValue = parseInt(response.unmaskedValue);
			maskedValue.value = response.maskedValue;

			if (response.unmaskedValue.length === MAX_CEP_LENGTH || response.unmaskedValue.length == 0) {
				emit('update:modelValue', parsedValue);
				getAddress(parsedValue);
			}
		}

		return () => (
			<NInput
				inputProps={{
					autocomplete: 'postal-code',
					inputmode: 'numeric',
				}}
				placeholder="00000-000"
				maxlength="9"
				loading={loading.value}
				onInput={handleInput}
				v-model:value={maskedValue.value}
			/>
		);
	},
});

export default AppInputCep;
