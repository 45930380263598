import { createApp } from 'vue';
import { createPinia } from 'pinia';
import * as Sentry from '@sentry/vue';

const pinia = createPinia();
import WebAppAlmada from './App';
import router from './router/router';

import 'basscss/css/basscss.css';
import './assets/main.scss';
import { vMaska } from 'maska/vue';

const app = createApp(WebAppAlmada);

if (process.env.NODE_ENV !== 'development') {
	Sentry.init({
		app,
		dsn: 'https://786ebb74e9eb480399b29bb6688b8f18@o4505132988366849.ingest.sentry.io/4505133005144064',
		integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
		// Performance Monitoring
		tracesSampleRate: 0.4,
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
	});
}

app.directive('maska', vMaska);

app.use(pinia);
app.use(router);

app.mount('#app');
