import type { ICaseSumarizedItem } from './ICaseSumarizedItem';

export interface IGridifyPaging<T> {
  data: Array<T>;
  count: number;
  sumarized: Array<ICaseSumarizedItem>;
}

export class GridifyPaging<T> implements IGridifyPaging<T> {
  data: Array<T>;
  count: number;
  sumarized: Array<ICaseSumarizedItem>;

  constructor() {
    this.data = new Array<T>();
    this.count = 0;
    this.sumarized = new Array<ICaseSumarizedItem>();
  }
}
