import { NIcon, NText } from 'naive-ui';
import { defineComponent, type Component, type PropType } from 'vue';

const MOBILE_ICON_SIZE = 24;

export const AppMobileMenuItem = defineComponent({
	name: 'AppMobileMenuItem',
	emits: ['clicked'],

	props: {
		label: {
			type: String,
			required: true,
		},
		icon: {
			type: Object as PropType<Component | null>,
			required: true,
		},
		router: {
			type: String,
		},
	},

	render() {
		return (
			<div
				class="text-center clickable"
				onClick={() =>
					this.router
						? [this.$router.push({ name: this.router }), this.$emit('clicked', this.label)]
						: this.$emit('clicked', this.label)
				}
			>
				<div style="margin-bottom: -5px">
					<NIcon component={this.icon} size={MOBILE_ICON_SIZE} />
				</div>

				<NText style="font-size: 13px" strong>
					{this.label}
				</NText>
			</div>
		);
	},
});
