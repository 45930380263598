import { Icons } from '@/utils';
import { NIcon, NInput } from 'naive-ui';
import { defineComponent, withKeys } from 'vue';

export const AppHeaderSearchBar = defineComponent({
	name: 'AppHeaderSearchBar',

	emits: {
		search: (query: string) => true,
	},

	data: () => ({
		searchQuery: '',
	}),

	render() {
		return (
			<NInput
				style="margin-top: 4px"
				minlength={3}
				type="text"
				placeholder="Pesquisar"
				v-model:value={this.searchQuery}
				//@ts-ignore
				onKeypress={withKeys(() => this.$emit('search', this.searchQuery), ['enter'])}
			>
				{{
					suffix: () => <NIcon size="24" component={Icons.Search} />,
				}}
			</NInput>
		);
	},
});
