import type {
	IGridifyPaging,
	INotification,
	IResponseBase,
	ISubscribe,
	IUser,
	IUserListItem,
} from '@/interfaces';
import type { GridifyQuery, GridifyQueryBuilder, IGridifyQuery } from '@/models';
import { Http } from './http';
import type { SubscribeType } from '@/constants';

const http = Http.create();

export class UserService {
	static async addUser(user: IUser) {
		const response = await http.put<IResponseBase<IUser>>('/users', user);

		return response.data;
	}

	/**
	 * Get a paginated user result
	 * @returns
	 */
	static async getUsers(query: IGridifyQuery | null = null) {
		const response = await http.get<IGridifyPaging<IUserListItem>>('/users', {
			params: query,
		});
		return response.data;
	}

	/**
	 * Get a user info
	 * @param id
	 * @returns
	 */
	static async getUser(id: string) {
		const response = await http.get<IResponseBase<IUser>>(`/users/${id}`);
		return response.data;
	}

	/**
	 * Update user infos
	 * @param user
	 * @returns
	 */
	static async updateUser(user: IUser) {
		const response = await http.post<IResponseBase<IUser>>(`/users/${user.id}`, user);
		return response.data;
	}

	static async getNotifications() {
		const response = await http.get<IResponseBase<INotification<any>[]>>('user/notifications');
		return response.data;
	}

	static async getSubscribes() {
		const response = await http.get<IResponseBase<ISubscribe[]>>('user/subscribes');
		return response.data;
	}

	static async readNotifications() {
		const response = await http.post<IResponseBase<INotification<any>[]>>('user/notifications');
		return response.data;
	}

	static async subscribe(subscribeType: SubscribeType, entityId: string | null = null) {
		const response = await http.post<IResponseBase<ISubscribe[]>>('user/subscribes', {
			subscribeType,
			entityId,
		});
		return response.data;
	}

	static async resetUserPassword(userId: string) {
		const response = await http.post<IResponseBase<string>>(`user/${userId}/reset-password`);
		return response.data;
	}

	static async changeUserStatus(userId: string) {
		const response = await http.post<IResponseBase<IUserListItem>>(`user/${userId}/change-status`);
		return response.data;
	}
}

export default UserService;
