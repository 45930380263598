import type { IBankAccountBase } from "@/interfaces";
import { BankAccountFormSchema } from "@/schemas";
import { NForm, NFormItemGi, NGrid, NInput, type FormInst } from "naive-ui";
import { defineComponent, ref } from "vue";
import { AppSelectBank } from "../Inputs";
import { defineForm } from "./FormBase";

export const BankAccountForm = defineComponent({
    name: "BankAccountForm",
    extends: defineForm<IBankAccountBase>(),

    props: {
        readonlyBankSelect: {
            type: Boolean,
            default: false
        }
    },

    render() {
        return (
            <NForm size="large" ref="formRef" model={this.formData} rules={BankAccountFormSchema} onSubmit={this.handleSubmit}>
                <NGrid cols="4" x-gap="4" y-gap="4">
                    <NFormItemGi label="Banco" path="bankId">
                        <AppSelectBank readonly={this.readonlyBankSelect} v-model={this.formData.bankId} />
                    </NFormItemGi>

                    <NFormItemGi label="Agência" path="agency">
                        <NInput placeholder="" v-model:value={this.formData.agency} />
                    </NFormItemGi>

                    <NFormItemGi label="Número" path="number">
                        <NInput placeholder="" v-model:value={this.formData.number} />
                    </NFormItemGi>

                    <NFormItemGi label="Chave Pix" path="pix">
                        <NInput placeholder="" v-model:value={this.formData.pix} />
                    </NFormItemGi>
                </NGrid>
            </NForm>
        )
    }
})

export default BankAccountForm;