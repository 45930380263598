import { FormValidations } from '@/utils';

export const AddressFormSchema = {
  // propertyType: [FormValidations.IsRequired],
  zipCode: [FormValidations.IsRequired],
  streetName: [FormValidations.IsRequired],
  number: [FormValidations.IsRequired],
  city: [FormValidations.IsRequired],
  district: [FormValidations.IsRequired],
  state: [FormValidations.IsRequired],
};

export default AddressFormSchema;
