import { GridifyFilter } from '@/constants/GridifyFilter';
import router from '@/router/router';
import { UserStore } from '@/stores/userStore';
export enum LogicalOperator {
	And = ',',
	Or = '|',
}

interface IGridifyFilter {
	field?: string;
	operator?: string;
	value?: string | number | boolean;
	connector?: string;
	nestedFilters?: IGridifyFilter[];
}

export interface IGridifyQuery {
	page: number;
	pageSize: number;
	orderBy: string | null | undefined;
	filter?: string;
}

/**
 * @deprecated Use GridifyQueryBuilder instead
 */
export class GridifyQuery {
	page: number = 1;
	pageSize: number = 30;
	orderBy!: string | null | undefined;
	filter!: string;

	public static get _localStorageKey() {
		const user = UserStore();
		//@ts-ignore
		return `${process.env.npm_package_version}:${
			user.userClaims.Id
		}:${router.currentRoute.value.path.replaceAll('/', '__')}_config`;
	}

	constructor(page: number, pageSize: number, localStorageKey: string | undefined = undefined) {
		if (!localStorageKey) localStorageKey = GridifyQuery._localStorageKey;

		var objectFilter =
			localStorage.getItem(localStorageKey || GridifyQuery._localStorageKey) != null
				? JSON.parse(localStorage.getItem(localStorageKey || GridifyQuery._localStorageKey) || '')
				: {
						page: page,
						pageSize: pageSize,
						filter: '',
						orderBy: '',
				  };

		this.page = objectFilter?.page || page;
		this.pageSize = objectFilter?.pageSize || pageSize;
		this.filter = objectFilter?.filter || '';
		this.orderBy = objectFilter?.orderBy || '';
	}

	clearFilters() {
		this.filter = '';
		this.saveToLocalStorage();
	}

	setFilter(filter: readonly IGridifyFilter[]) {
		this.filter = this.buildQueryString(filter);
		this.saveToLocalStorage();
	}

	addOrderBy(orderBy: string) {
		this.orderBy = orderBy;
		this.saveToLocalStorage();
	}

	setPage(page: number) {
		this.page = page;
		this.saveToLocalStorage();
	}

	buildQueryString(filters: readonly IGridifyFilter[], connector: string = ','): string {
		const result = filters
			.filter(
				(filter) =>
					(filter.field && filter.operator && filter.value) ||
					(filter.nestedFilters &&
						filter.nestedFilters.length > 0 &&
						filter.nestedFilters.filter((f) => f.field && f.operator && f.value).length > 0),
			)
			.map((filter) => {
				let value = filter.value;

				//if filter.value is array
				if (Array.isArray(filter.value))
					return `(${filter.value.map((v) => `${filter.field}=${v}`).join('|')})`;

				if (filter.nestedFilters)
					return `(${this.buildQueryString(filter.nestedFilters, filter.connector || ',')})`;

				if (filter.operator === GridifyFilter.Contains)
					return `${filter.field}${filter.operator}${value}/i`;

				return `${filter.field}${filter.operator}${value}`;
			})
			.join(connector);

		return result;
	}

	currentFilter(): { [key: string]: string } {
		if (this.filter.length == 0) return {};

		const keyValuePairs = this.filter.split(',');

		const jsonObject: any = {};

		keyValuePairs.forEach((pair) => {
			const [key, value] = pair.split('=');
			jsonObject[key.trim()] = value.trim();
		});

		return jsonObject;
	}

	private saveToLocalStorage() {
		const serializedObject = JSON.stringify({
			page: this.page,
			pageSize: this.pageSize,
			orderBy: this.orderBy,
			filter: this.filter,
		});

		localStorage.setItem(GridifyQuery._localStorageKey, serializedObject);
	}
}

export class GridifyQueryBuilder {
	private query: IGridifyQuery = {
		page: 1,
		pageSize: 20,
		orderBy: '',
		filter: '',
	};

	private orderByGroup = {} as {
		[key: string]: string;
	};

	private filteringExpressions: IExpression[] = [];

	public static get _localStorageKey() {
		const user = UserStore();
		//@ts-ignore
		return `${process.env.npm_package_version}:${
			user.userClaims.Id
		}:${router.currentRoute.value.path.replaceAll('/', '______')}_config`;
	}

	constructor(useLocalStorage = true) {
		var querySaved =
			localStorage.getItem(GridifyQueryBuilder._localStorageKey) != undefined && useLocalStorage
				? JSON.parse(localStorage.getItem(GridifyQueryBuilder._localStorageKey) || '')
				: null;

		if (querySaved) {
			this.query = querySaved.query;
			this.filteringExpressions = querySaved.filteringExpressions;
			this.orderByGroup = querySaved.orderByGroup;

			// remap filteringExpressions from filter string
		}
	}

	setPage(page: number): GridifyQueryBuilder {
		this.query.page = page;
		return this;
	}

	setPageSize(pageSize: number): GridifyQueryBuilder {
		this.query.pageSize = pageSize;
		return this;
	}

	hasOrderBy(field: string) {
		return !!this.orderByGroup[field];
	}

	addOrderBy(field: string, descending: boolean = false): GridifyQueryBuilder {
		this.orderByGroup = {};

		const expression = `${field.trim()} ${descending ? 'desc' : ''}`.trim();

		this.orderByGroup[field] = expression;

		return this;
	}

	hasCondition(field: string) {
		return this.filteringExpressions.some((exp) => exp.value.startsWith(field));
	}

	addCondition(
		field: string,
		operator: GridifyFilter,
		value: string | number | boolean | undefined | null,
		caseSensitive: boolean = true,
		escapeValue: boolean = true,
		override = false,
	): GridifyQueryBuilder {
		let filterValue = value;
		if (escapeValue && typeof value === 'string') {
			filterValue = value.replace(/([(),|]|\/i)/g, '\\$1');
		}

		if (value == undefined) {
			// remove the condition
			this.filteringExpressions = this.filteringExpressions.filter((exp) => exp.field !== field);
			return this;
		}

		if (!caseSensitive && value) {
			filterValue = `${filterValue.toString()}/i`;
		}

		const filterExpression = `${field.trim()}${operator}${filterValue}`;
		let existingIndex = this.filteringExpressions.findIndex((expr) =>
			expr.value.startsWith(filterExpression),
		);

		// if override is true, should update the existing filter expression key without duplicate
		if (override) {
			existingIndex = this.filteringExpressions.findIndex((exp) => exp.field == field);
		}

		if (existingIndex !== -1) {
			// Update the existing filter expression
			this.filteringExpressions[existingIndex].value = filterExpression;
		} else {
			// Add a new filter expression
			this.filteringExpressions.push({
				field: field,
				fieldValue: value,
				value: filterExpression,
				type: 'filter',
			});
		}

		this.removeTrailingAnd();

		return this;
	}

	removeCondition(field: string): GridifyQueryBuilder {
		this.filteringExpressions = this.filteringExpressions.filter((exp) => exp.field !== field);
		return this;
	}

	isOrderByEmpty() {
		return Object.keys(this.orderByGroup).length === 0;
	}

	private removeTrailingAnd() {
		if (this.filteringExpressions.length > 0) {
			const lastExpression = this.filteringExpressions[this.filteringExpressions.length - 1];
			if (
				lastExpression.value.trim().toUpperCase() === LogicalOperator.And ||
				lastExpression.value.trim().toUpperCase() === LogicalOperator.Or
			) {
				this.filteringExpressions.pop();
			}
		}
	}

	startGroup(): GridifyQueryBuilder {
		this.filteringExpressions.push({ value: '(', type: 'startGroup' });
		return this;
	}

	endGroup(): GridifyQueryBuilder {
		this.filteringExpressions.push({ value: ')', type: 'endGroup' });
		return this;
	}

	and(): GridifyQueryBuilder {
		this.filteringExpressions.push({
			value: LogicalOperator.And,
			type: 'op',
		});
		return this;
	}

	or(): GridifyQueryBuilder {
		this.filteringExpressions.push({ value: LogicalOperator.Or, type: 'op' });
		return this;
	}

	rebuild(): GridifyQueryBuilder {
		this.query.filter = '';
		this.query.orderBy = '';

		return this;
	}

	reset() {
		this.filteringExpressions = [];
		this.query.filter = '';
		this.query.orderBy = '';
		this.orderByGroup = {};
		this.setPage(1);

		this.saveState();

		return this;
	}

	saveState() {
		localStorage.setItem(
			GridifyQueryBuilder._localStorageKey,
			JSON.stringify({
				query: this.query,
				filteringExpressions: this.filteringExpressions,
				orderByGroup: this.orderByGroup,
			}),
		);
	}

	build(): IGridifyQuery {
		let previousType: 'filter' | 'op' | 'startGroup' | 'endGroup' | null = null;
		let groupCounter = 0;

		this.query.filter = '';

		this.filteringExpressions.forEach((exp, index) => {
			if (exp.type === 'startGroup') {
				if (previousType === 'filter' || previousType === 'endGroup') {
					this.query.filter += LogicalOperator.And;
				}
				this.query.filter += '(';
				groupCounter++;
			} else if (exp.type === 'endGroup') {
				this.query.filter += ')';
				groupCounter--;
			} else if (exp.type === 'filter') {
				if (previousType === 'filter' || previousType === 'endGroup') {
					if (this.filteringExpressions[index - 1]?.type !== 'op') {
						this.query.filter += LogicalOperator.And;
					}
				}
				this.query.filter += exp.value;
			} else if (exp.type === 'op') {
				this.query.filter += exp.value;
			}

			// Remove empty groups like this ",()""
			if (this.query.filter?.endsWith(',()')) {
				// use replace
				this.query.filter = this.query.filter.replace(',()', '');
			}

			previousType = exp.type;
		});
		// Check for unbalanced groups
		if (groupCounter !== 0) {
			throw new Error('Unbalanced groups in query filter');
		}

		try {
			this.validate();
		} catch (e) {
			//console.log(this.query.filter);
		}

		this.query.orderBy = Object.keys(this.orderByGroup)
			.map((item) => this.orderByGroup[item])
			.join(', ');

		this.query.filter = this.query.filter.replace('id=*', 'id=');

		this.saveState();

		return this.query;
	}

	validate() {
		this.filteringExpressions.forEach((exp) => {
			let previousType: 'filter' | 'op' | 'startGroup' | 'endGroup' | null = null;
			let groupCounter = 0;

			if (exp.type === 'startGroup') {
				groupCounter++;
			}
			if (exp.type === 'endGroup') {
				groupCounter--;
			}

			//,
			if (previousType === null && exp.type === 'op') {
				throw new Error('expression cannot start with a logical operator');
			}

			// filter filter
			if (previousType === 'filter' && exp.type === 'filter') {
				throw new Error(
					'consecutive conditions are not allowed, consider adding a logical operator',
				);
			}

			// ,,
			if (previousType === 'op' && exp.type === 'op') {
				throw new Error('consecutive operators are not allowed, consider adding a filter');
			}

			// (,
			if (previousType === 'startGroup' && exp.type === 'op') {
				throw new Error('logical operator immediately after startGroup is not allowed');
			}

			// )filter
			if (previousType === 'endGroup' && exp.type === 'filter') {
				throw new Error('Missing logical operator after endGroup');
			}

			// ()
			if (previousType === 'startGroup' && exp.type === 'endGroup') {
				throw new Error('Empty groups are not allowed');
			}

			// )(
			if (previousType === 'endGroup' && exp.type === 'startGroup') {
				throw new Error('Missing a logical operator between groups');
			}
		});
	}

	removeDuplicateItems(input: string): string {
		const items = input.split(',');

		const uniqueItems = [...new Set(items)];

		return uniqueItems.join(',');
	}

	getFilters() {
		return this.filteringExpressions.filter((c) => c.type === 'filter');
	}
}

export interface IExpression {
	field?: string;
	fieldValue?: string | number | boolean;
	value: string;
	type: 'filter' | 'op' | 'startGroup' | 'endGroup';
}
