import * as signalR from '@microsoft/signalr';
import { auth } from '.';

let baseUrl = import.meta.env.VITE_API_SERVICE;

export class HubsService {
	static async initCaseHub(caseId: string) {
		const hub = new signalR.HubConnectionBuilder();
		var connection = hub
			.withUrl(`${baseUrl}/hub/case?caseId=${caseId}`)
			.withAutomaticReconnect()
			.build();

		await connection
			.start()
			.then(() => console.log('Connection Started!'))
			.catch((err) => console.log('Error while establishing a connection :( '));

		return {
			connection,
			onMessageReceived: (callback: (caseId: string) => void) =>
				connection.on('Case:Updated', callback),
			onActivityUpdated: (callback: (caseId: string) => void) =>
				connection.on('Case:Activity:Updated', callback),
		};
	}

	static async initNoficationsHub() {
		const hub = new signalR.HubConnectionBuilder();
		const token = await auth.currentUser?.getIdToken(true);
		var connection = hub
			.withUrl(`${baseUrl}/hub/notification?token=${token}`)
			.withAutomaticReconnect()
			.build();

		await connection
			.start()
			.catch((err) => console.log('Error while establishing a connection :( '));

		return {
			connection,
			onNotificationReceived: (callback: () => void) =>
				connection.on('Notification:Created', callback),
		};
	}

	static async initChatHub(entityId: string, entityType: string) {
		const hub = new signalR.HubConnectionBuilder();
		var connection = hub
			.withUrl(`${baseUrl}/hub/chat?roomId=${entityId}&entityType=${entityType}`)
			.build();

		await connection.start();

		return {
			connection,
			onMessageReceived: (callback: (chatId: string) => void) =>
				connection.on('Chat:Message:Received', callback),
		};
	}

	static async initSuperChatHub() {
		const hub = new signalR.HubConnectionBuilder();
		var connection = hub.withUrl(`${baseUrl}/hub/superchat`).build();

		await connection.start();

		return {
			connection,
			onMessageReceived: (callback: (roomId: string) => void) =>
				connection.on('Chat:Message:Received', callback),
		};
	}
}
