import {
	NButton,
	NCard,
	NForm,
	NFormItem,
	NImage,
	NInput,
	NSpace,
	NSpin,
	useMessage,
	type FormInst,
} from 'naive-ui';
import { defineComponent, reactive, ref } from 'vue';

import logo from '@/assets/logo.svg';
import { Messages } from '@/constants';
import { AuthService } from '@/services';
import { FormValidations } from '@/utils';
import { LoadingZoo } from './LoadingZoo';

interface ILoginForm {
	email: string;
	password: string;
}

export const AppLogin = defineComponent({
	setup() {
		const message = useMessage();
		//@ts-ignore
		window.$message = message;
		const formLogin = ref<FormInst | null>(null);
		const isLoading = ref(true);
		const formData = reactive<ILoginForm>({
			email: '',
			password: '',
		});

		return {
			isLoading,
			formLogin,
			logo,
			formData,
			rules: {
				email: [FormValidations.IsEmailValid],
				password: [FormValidations.IsRequired],
			},
			handleSubmit(e: Event) {
				e.preventDefault();
				formLogin.value?.validate(async (errors) => {
					if (!errors) {
						const messageReactive = message.loading(Messages.LOGIN_LOADING, { duration: 0 });

						try {
							await AuthService.login(formData.email, formData.password);
							messageReactive.destroy();
							message.success(Messages.LOGIN_SUCCESS);
						} catch {
							messageReactive.destroy();
							formData.password = '';
							message.error(Messages.ERROR_INVALID_LOGIN);
						}
					} else {
						console.log(errors);
					}
				});
			},
		};
	},
	async mounted() {
		this.isLoading = true;
		await AuthService.waitForAuthInit();
		this.isLoading = false;
	},
	render() {
		return (
			<NSpace vertical justify="center" align="center" style="height:100%;">
				<NCard>
					{!this.isLoading && (
						<div data-cy="is-login-page">
							<div>
								<NImage
									src={this.logo}
									preview-disabled
									object-fit="contain"
									width="320"
									class="m2 mx-auto text-center block"
								/>
							</div>
							<NForm
								size="large"
								ref="formLogin"
								show-label={false}
								model={this.formData}
								rules={this.rules}
								onSubmit={this.handleSubmit}
							>
								<NFormItem path="email">
									<NInput
										data-cy="input-username"
										v-model:value={this.formData.email}
										placeholder="Email"
										clearable
									/>
								</NFormItem>
								<NFormItem path="password">
									<NInput
										data-cy="input-password"
										v-model:value={this.formData.password}
										show-password-on="click"
										type="password"
										clearable
										placeholder="Senha"
									/>
								</NFormItem>

								<NSpace justify="end">
									<NButton data-cy="btn-submit-login" class="mt2" type="primary" attr-type="submit">
										Entrar
									</NButton>
								</NSpace>
							</NForm>
						</div>
					)}

					{this.isLoading && <LoadingZoo />}
				</NCard>
			</NSpace>
		);
	},
});

export default AppLogin;
