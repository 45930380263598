import MenuItem from '@/models/menuItem';
import { UserStore as useUserStore } from '@/stores';
import { Icons, Routes } from '@/utils';
import type { MenuOption } from 'naive-ui';
import { defineComponent } from 'vue';

import { CaptureEvent, Events } from '@/services/amplitudeService';
import router from '@/router/router';

export const AppMenu = defineComponent({
	data: () => ({
		searchQuery: '',
		activeKey: '',
	}),

	computed: {
		/**
		 * @deprecated Use menuOptions instead
		 */
		menuOptions() {
			const userStore = useUserStore();

			var subMenuManagment: MenuOption[] = [];

			if (userStore.isUserAdmin || userStore.isBusinessConsultant || userStore.isBusinessManager)
				subMenuManagment.push(MenuItem.create('Parceiros', Routes.Brokers));
			if (
				userStore.isUserAdmin ||
				userStore.isBrokerAdmin ||
				userStore.isBusinessConsultant ||
				userStore.isBusinessManager
			)
				subMenuManagment.push(MenuItem.create('Usuários', Routes.ManagementUsers));
			if (userStore.isUserAdmin)
				subMenuManagment.push(
					MenuItem.create('Política de Crédito', Routes.ManagementCreditPolicyView),
				);
			if (userStore.isUserAdmin || userStore.isUser)
				subMenuManagment.push(MenuItem.create('Financeiro', Routes.ManagementFinancialBrokerBills));

			return [
				MenuItem.create('Inicio', Routes.Home, Icons.Home),
				MenuItem.create('Simulador', Routes.Simulator, Icons.Simulator),
				MenuItem.create('Produtos', Routes.Cases, Icons.Product, [
					MenuItem.create(
						'Crédito Imobiliário',
						Routes.RealEstateFinancingCases,
						Icons.MortgageProduct,
					),
					MenuItem.create('Garantia Locatícia', '', Icons.Inbox, [
						MenuItem.create('Propostas', Routes.Cases, Icons.Proposal),
						MenuItem.create('Contratos', Routes.Contracts, Icons.Contract),
					]),
				]),
				MenuItem.create('Jurídico', Routes.Legal, Icons.Legal),
				userStore.isBrokerAdmin
					? MenuItem.create('Financeiro', Routes.BrokerFinancial, Icons.Financial)
					: undefined,
				userStore.isUserAdmin ||
				userStore.isBrokerAdmin ||
				userStore.isBusinessConsultant ||
				userStore.isBusinessManager
					? MenuItem.create(
							'Gerenciamento',
							Routes.Management,
							Icons.GroupOutlined,
							subMenuManagment,
					  )
					: undefined,
			].filter((c) => c != undefined) as MenuOption[];
		},

		menuItems() {
			const userStore = useUserStore();

			var subMenuManagment = new Array<MenuItem>();

			if (userStore.isUserAdmin || userStore.isBusinessConsultant || userStore.isBusinessManager)
				subMenuManagment.push(new MenuItem('Parceiros', Routes.Brokers, Icons.Settings));
			if (
				userStore.isUserAdmin ||
				userStore.isBrokerAdmin ||
				userStore.isBusinessConsultant ||
				userStore.isBusinessManager
			)
				subMenuManagment.push(new MenuItem('Usuários', Routes.ManagementUsers, Icons.User));
			if (userStore.isUserAdmin)
				subMenuManagment.push(
					new MenuItem('Política de Crédito', Routes.ManagementCreditPolicyView, Icons.Settings),
				);
			if (userStore.isUserAdmin || userStore.isUser)
				subMenuManagment.push(
					new MenuItem('Financeiro', Routes.ManagementFinancialBrokerBills, Icons.Settings),
				);

			return [
				new MenuItem('Geral', '', Icons.Home, [
					new MenuItem('Inicio', Routes.Home, Icons.Home),
					new MenuItem('Simulador', Routes.Simulator, Icons.Simulator),
				]),

				new MenuItem('Crédito Imobiliário', '', Icons.MortgageProduct, [
					new MenuItem('Criar proposta', Routes.RealEstateFinancingCreate, Icons.AddProposal),
					new MenuItem('Propostas', Routes.RealEstateFinancingCases, Icons.MortgageProduct),
				]),

				new MenuItem('Garantia Locatícia', '', Icons.Inbox, [
					new MenuItem('Criar proposta', Routes.CaseCreateView, Icons.AddProposal),
					new MenuItem('Propostas', Routes.Cases, Icons.Proposal),
					new MenuItem('Contratos', Routes.Contracts, Icons.Contract),
				]),

				new MenuItem('Jurídico', '', Icons.Legal, [
					new MenuItem('Acessar Jurídico', Routes.Legal, Icons.Legal),
					new MenuItem('Ocorrências', Routes.Legal, Icons.Legal),
					new MenuItem('Acordos', Routes.Legal, Icons.Legal),
				]),
				userStore.isBrokerAdmin
					? new MenuItem('Financeiro', Routes.BrokerFinancial, Icons.Financial)
					: undefined,
				userStore.isUserAdmin ||
				userStore.isBrokerAdmin ||
				userStore.isBusinessConsultant ||
				userStore.isBusinessManager
					? new MenuItem('Gerenciamento', Routes.Management, Icons.GroupOutlined, subMenuManagment)
					: undefined,
			].filter((c) => c != undefined);
		},

		currentTheme() {
			const userStore = useUserStore();
			return userStore.theme;
		},
	},

	methods: {
		handleSearch(query: string) {
			if (!!query) router.push({ name: Routes.Search, query: { query } });
		},

		toggleTheme() {
			const userStore = useUserStore();
			userStore.toggleTheme();
			CaptureEvent(Events.ToggleTheme, { theme: userStore.theme });
		},
	},
});
