import type { IAddressBase, IViaCepResponse } from '@/interfaces';
import { AddressFormSchema } from '@/schemas';
import { NGrid, NFormItemGi, NInput, NForm, NSelect, type FormRules } from 'naive-ui';
import { defineComponent } from 'vue';
import { AppInputCep } from '../Inputs/AppInputCep';
import { defineForm } from './FormBase';

export interface IAddressForm {
	validate(): Promise<unknown>;
}
export const AddressForm = defineComponent({
	name: 'AddressForm',
	extends: defineForm<IAddressBase, FormRules>({
		schema: AddressFormSchema as unknown as FormRules,
	}),

	methods: {
		populateAddress(address: IViaCepResponse) {
			this.formData.city = address.localidade;
			this.formData.state = address.uf;
			this.formData.streetName = address.logradouro;
			this.formData.district = address.bairro;
		},
	},

	render() {
		return (
			<NForm
				ref="formRef"
				{...{ attrs: this.$attrs }}
				label-placement="top"
				rules={this.formSchema}
				model={this.formData}
				onSubmit={this.handleSubmit}
			>
				<NGrid cols="1 400:4" x-gap="4" y-gap="4">
					<NFormItemGi label="CEP" path="zipCode">
						<AppInputCep v-model={this.formData.zipCode} onAddress={this.populateAddress} />
					</NFormItemGi>

					<NFormItemGi span="2" label="Endereço" path="streetName">
						<NInput placeholder="Rua, Avenida e etc..." v-model:value={this.formData.streetName} />
					</NFormItemGi>

					<NFormItemGi label="Número" path="number">
						<NInput placeholder="Número" v-model:value={this.formData.number} />
					</NFormItemGi>

					<NFormItemGi label="Complemento" path="additional">
						<NInput placeholder="Bloco A - Sala 515" v-model:value={this.formData.additional} />
					</NFormItemGi>

					<NFormItemGi label="Bairro" path="district">
						<NInput placeholder="Bairro" v-model:value={this.formData.district} />
					</NFormItemGi>

					<NFormItemGi label="Cidade" path="city">
						<NInput placeholder="Cidade" v-model:value={this.formData.city} />
					</NFormItemGi>

					<NFormItemGi span="1" label="UF" path="state">
						<NSelect
							v-model:value={this.formData.state}
							options={[
								{ value: 'AC', label: 'Acre' },
								{ value: 'AL', label: 'Alagoas' },
								{ value: 'AM', label: 'Amazonas' },
								{ value: 'AP', label: 'Amapá' },
								{ value: 'BA', label: 'Bahia' },
								{ value: 'CE', label: 'Ceará' },
								{ value: 'DF', label: 'Distrito Federal' },
								{ value: 'ES', label: 'Espírito Santo' },
								{ value: 'GO', label: 'Goiás' },
								{ value: 'MA', label: 'Maranhão' },
								{ value: 'MG', label: 'Minas Gerais' },
								{ value: 'MS', label: 'Mato Grosso do Sul' },
								{ value: 'MT', label: 'Mato Grosso' },
								{ value: 'PA', label: 'Pará' },
								{ value: 'PB', label: 'Paraíba' },
								{ value: 'PE', label: 'Pernambuco' },
								{ value: 'PI', label: 'Piauí' },
								{ value: 'PR', label: 'Paraná' },
								{ value: 'RJ', label: 'Rio de Janeiro' },
								{ value: 'RN', label: 'Rio Grande do Norte' },
								{ value: 'RO', label: 'Rondônia' },
								{ value: 'RR', label: 'Roraima' },
								{ value: 'RS', label: 'Rio Grande do Sul' },
								{ value: 'SC', label: 'Santa Catarina' },
								{ value: 'SE', label: 'Sergipe' },
								{ value: 'SP', label: 'São Paulo' },
								{ value: 'TO', label: 'Tocantins' },
							]}
						/>
					</NFormItemGi>
				</NGrid>
			</NForm>
		);
	},
});

export default AddressForm;
