import type { IBank } from "@/interfaces";
import { GeneralService } from "@/services";
import { NSelect } from "naive-ui";
import { computed, defineComponent, ref } from "vue";

export const AppSelectBank = defineComponent({
    emits: ["update:modelValue", "update:value"],

    props: {
        modelValue: String,
        readonly: Boolean
    },

    async mounted() {
        this.loading = true;
        const response = await GeneralService.getBanks();
        this.banks = response.data;
        this.loading = false;
    },

    setup(props, { emit }) {
        const loading = ref(true);
        const inputData = ref(props.modelValue ?? "");
        const banks = ref(new Array<IBank>());

        const banksOptions = computed(() => {
            return banks.value.map((bank) => ({
                value: bank.code,
                label: `${bank.code} - ${bank.longName}`
            }))
        })

        function handleInput(value: string) {
            emit("update:modelValue", value);
            emit("update:value", value);

        }

        return { loading, banks, banksOptions, inputData, handleInput }

    },

    render() {
        return <NSelect
            filterable
            v-model:value={this.inputData}
            options={this.banksOptions}
            onUpdateValue={this.handleInput}
            disabled={this.readonly}
            inputProps={{
                autocomplete: 'disabled',
            }}
            placeholder="Selecione um banco"
        />
    }
})

export default AppSelectBank