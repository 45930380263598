import { diffDays, diffMonths, diffYears, parse, addDay } from '@formkit/tempo';
import { endOfMonth, format, isThisWeek, isThisYear, isToday, isYesterday } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export class DateHelper {
	static parseDate(date: string, format: string = '') {
		// if date contains /
		if (!date) return new Date(0, 0, 0);
		if (date.includes('/')) return parse(date, 'DD/MM/YYYY');

		if (format != '') return parse(date, format);
		return parse(date);
	}

	static diffBetweenDates(date1: string | Date, date2: string | Date, type: string = 'days') {
		if (typeof date1 === 'string') date1 = parse(date1);
		if (typeof date2 === 'string') date2 = parse(date2);

		switch (type) {
			case 'days':
				return diffDays(date1, date2);
			case 'months':
				return diffMonths(date1, date2);
			case 'years':
				return diffYears(date1, date2);
		}

		return -1;
	}

	static getWelcomeMessage() {
		var today = new Date();
		var curHr = today.getHours();

		if (curHr < 12) {
			return 'Bom dia';
		} else if (curHr < 18) {
			return 'Boa tarde';
		} else {
			return 'Boa noite';
		}
	}

	static toFormat(date: string | Date) {
		let _date = new Date();
		if (typeof date === 'string') _date = parse(date);
		if (typeof date !== 'string') _date = date;

		return _date.toLocaleString('pt-BR');
	}

	static toMonthName(monthNumber: number | string) {
		const date = new Date();
		date.setMonth(Number(monthNumber) - 1);

		return date.toLocaleString('pt-BR', { month: 'long' });
	}

	static toShortFormat(date: string | Date | undefined) {
		if (!date) return '-';

		let _date = new Date();
		if (typeof date === 'string') _date = new Date(date);
		if (typeof date !== 'string') _date = date;

		return _date.toLocaleDateString('pt-BR');
	}

	static toDateMonthName(date: string | Date | undefined) {
		if (!date) return '-';

		let _date = new Date();
		if (typeof date === 'string') _date = new Date(date);
		if (typeof date !== 'string') _date = date;

		return _date
			.toLocaleDateString('pt-BR', { month: 'long', year: 'numeric' })
			.toLocaleUpperCase('pt-BR');
	}

	/**
	 * Return a time ago
	 * @param date
	 * @returns
	 */
	static getTimeAgo(date: Date | string, hiddenAgo = false) {
		if (typeof date === 'string') date = new Date(date);

		const now = new Date();
		const diff = Math.abs(now.getTime() - date.getTime());

		const minute = 60 * 1000;
		const hour = minute * 60;
		const day = hour * 24;
		const week = day * 7;
		const month = day * 30;
		const year = day * 365;

		if (diff < minute) {
			return Math.floor(diff / 1000) + ' segundos atrás';
		} else if (diff < hour) {
			return Math.floor(diff / minute) + ' minutos atrás';
		} else if (diff < day) {
			return Math.floor(diff / hour) + ' horas atrás';
		} else if (diff < week) {
			return Math.floor(diff / day) + ' dias atrás';
		} else if (diff < month) {
			return Math.floor(diff / week) + ' semanas atrás';
		} else if (diff < year) {
			return Math.floor(diff / month) + ' meses atrás';
		} else {
			return Math.floor(diff / year) + ' anos atrás';
		}
	}

	static formatMessageTime(date: Date | string): string {
		if (typeof date === 'string') date = new Date(date);

		if (!date) return '';

		// Invalid Date
		if (isNaN(date.getTime())) return '';

		if (isToday(date)) {
			return format(date, 'HH:mm', { locale: ptBR });
		} else if (isYesterday(date)) {
			return 'Ontem';
		} else if (isThisWeek(date)) {
			return format(date, 'EEEE', { locale: ptBR });
		} else if (isThisYear(date)) {
			return format(date, 'dd/MM', { locale: ptBR });
		} else {
			return format(date, 'dd/MM/yyyy', { locale: ptBR });
		}
	}

	static monthFromNumber(month: number) {
		return new Date(0, month).toLocaleString('pt-BR', { month: 'long' });
	}

	static firstDayOfMonth(month: number, year: number) {
		console.log(month);
		return new Date(year, month - 1, 1);
	}

	static lastDayOfMonth(month: number, year: number) {
		return endOfMonth(new Date(year, month - 1, 1));
	}

	static daysToExpire(date: string) {}

	static addDays(date: Date, days: number) {
		return addDay(date, days);
	}
}
