import { StringMask } from '@/utils/StringMask';
import { format, parse } from '@formkit/tempo';
import { NDatePicker, NInput } from 'naive-ui';
import { defineComponent, ref, watch } from 'vue';

const MAX_INPUT_LENGTH = 11;

export const AppInputDate = defineComponent({
	name: 'AppInputDate',
	emits: ['update:modelValue', 'update:value'],
	props: {
		modelValue: String,
		filled: Boolean,
		disabled: Boolean,
		placeholder: String,
	},

	setup(props, { emit }) {
		const mask = '00/00/0000';
		const maskedValue = ref('');

		if (props.modelValue) fillField(props.modelValue);

		handleInput(props.modelValue);

		watch(
			() => props.modelValue,
			(value) => {
				if (value) {
					fillField(value);
				}
			},
		);

		function parseDate(value: string) {
			try {
				return parse(value, 'DD/MM/YYYY', 'pt-BR');
			} catch {
				return parse(value);
			}
		}

		function fillField(value: string) {
			if (value == '0001-01-01T00:00:00+00:00') return;

			var dateParsed = parseDate(value);
			maskedValue.value = format(dateParsed, 'DD/MM/YYYY');
		}

		function handleInput(value: string | undefined) {
			if (value == '0001-01-01T00:00:00+00:00') return;

			if (!value) return;
			if (value == null) return '';

			let dateParsed;

			if (value.length == 10) {
				dateParsed = parseDate(value);

				const isoDate = format(dateParsed, 'YYYY-MM-DD');

				emit('update:modelValue', isoDate);
				emit('update:value', isoDate);
			}
		}

		if (props.filled) handleInput(format(new Date(), 'DD/MM/YYYY'));

		return () => (
			<NInput
				v-maska
				disabled={props.disabled}
				input-props={{
					'data-maska': '##/##/####',
					//value: maskedValue.value,
				}}
				v-model:value={maskedValue.value}
				onInput={handleInput}
				placeholder={props.placeholder || 'DD/MM/AAAA'}
				//type="date"
			/>
		);
	},
});

export default AppInputDate;
