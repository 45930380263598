import {
	AlertTwotone,
	ArrowDownOutlined,
	AuditOutlined,
	BankTwotone,
	CheckCircleOutlined,
	CheckCircleTwotone,
	CheckSquareTwotone,
	CloudDownloadOutlined,
	CreditCardTwotone,
	DeleteTwotone,
	EditTwotone,
	ExperimentTwotone,
	FileAddTwotone,
	FileExcelOutlined,
	FilePdfTwotone,
	FileSearchOutlined,
	FileTextTwotone,
	FolderOpenTwotone,
	FolderTwotone,
	GroupOutlined,
	HomeTwotone,
	InboxOutlined,
	InfoCircleTwotone,
	LockTwotone,
	MessageTwotone,
	MoreOutlined,
	NotificationTwotone,
	ProjectTwotone,
	ReloadOutlined,
	SearchOutlined,
	SendOutlined,
	SettingTwotone,
	ShopTwotone,
	SwapOutlined,
	UserAddOutlined,
	WhatsAppOutlined,
} from '@vicons/antd';

import {
	ChatBubbleOutlineTwotone,
	CheckCircleOutlineTwotone,
	DarkModeOutlined,
	LabelTwotone,
	LightModeOutlined,
	LiveHelpTwotone,
	LockResetTwotone,
	LogOutTwotone,
	PixRound,
	PostAddTwotone,
	SecurityTwotone,
	SettingsTwotone,
	StoreTwotone,
	UpdateRound,
} from '@vicons/material';

export class Icons {
	static Sheets = FileExcelOutlined;
	static ChevronDown = ArrowDownOutlined;
	static Proposal = FileAddTwotone;
	static NewProposal = FileAddTwotone;
	static Contract = FileTextTwotone;
	static GroupOutlined = GroupOutlined;
	static Simulator = ExperimentTwotone;
	static MortgageProduct = BankTwotone;
	static Logout = LogOutTwotone;
	static User = UserAddOutlined;
	static FolderOutlined = FolderTwotone;
	static FolderOpen = FolderOpenTwotone;
	static Done = CheckCircleOutlineTwotone;
	static Download = CloudDownloadOutlined;
	static Open = FileSearchOutlined;
	static Home = HomeTwotone;
	static Commercial = ShopTwotone;
	static CreditCard = CreditCardTwotone;
	static Invoice = FileTextTwotone;
	static Pix = PixRound;
	static Invoiced = FileTextTwotone;
	static DarkMode = DarkModeOutlined;
	static LightMode = LightModeOutlined;
	static Label = LabelTwotone;
	static Inbox = InboxOutlined;
	static Settings = SettingTwotone;
	static Notification = NotificationTwotone;
	static Security = LockTwotone;
	static Info = InfoCircleTwotone;
	static MoreVert = MoreOutlined;
	static Delete = DeleteTwotone;
	static ResetPassword = LockResetTwotone;
	static Send = SendOutlined;
	static StatusToArrow = SwapOutlined;
	static Alert = AlertTwotone;
	static Checkmark = CheckSquareTwotone;
	static MessageEmpty = MessageTwotone;
	static Refresh = ReloadOutlined;
	static Edit = EditTwotone;
	static Search = SearchOutlined;
	static Product = ProjectTwotone;
	static Whatsapp = WhatsAppOutlined;
	static Update = ReloadOutlined;
	static Financial = ProjectTwotone;
	static ReportPDF = FilePdfTwotone;
	static Legal = AuditOutlined;
	static Signed = AuditOutlined;
	static TimelineCompleted = CheckCircleOutlined;
	static TimelinePending = InfoCircleTwotone;
	static ChatMessage = ChatBubbleOutlineTwotone;
	static FAQ = LiveHelpTwotone;
}

export default Icons;
