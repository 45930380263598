import type { AppColumn } from '@/constants/Columns';
import { GridifyPaging, type IGridifyPaging } from '@/interfaces/IGridifyPaging';
import { GridifyQueryBuilder } from '@/models';
import type { CaseResponseType } from '@/models/CaseResponseType';
import { useAppStore, UserStore as useUserStore } from '@/stores';
import type { DataTableColumns } from 'naive-ui';
import type { RowData, TableBaseColumn } from 'naive-ui/es/data-table/src/interface';
import { mapState, mapStores } from 'pinia';
import { defineComponent } from 'vue';

export const AppMappingStates = {
	computed: {
		...mapStores(useUserStore),
		...mapState(useUserStore, ['isUserAdmin']),
		...mapState(useUserStore, ['isUser']),
		...mapState(useUserStore, ['isBrokerAdmin']),
		...mapState(useUserStore, ['isBrokerAgent']),
		...mapState(useUserStore, ['isBusinessConsultant']),
		...mapState(useUserStore, ['isBusinessManager']),
		...mapState(useUserStore, ['isBrokerUser']),
		...mapState(useAppStore, ['appState']),
		...mapState(useAppStore, ['isMobile']),

		isBackOffice() {
			return this.isUser || this.isUserAdmin;
		},
	},
};

export const AppPageBaseView = defineComponent({
	extends: AppMappingStates,

	data: () => ({
		localLoading: true,
	}),

	emits: ['update'],

	methods: {
		setLoading(loading: boolean) {
			this.localLoading = loading;
		},
	},
});

export const AppPageBaseCaseQueryView = defineComponent({
	extends: AppPageBaseView,

	data: () => ({
		query: new GridifyQueryBuilder(),
		columns: [] as AppColumn<CaseResponseType>,
		result: new GridifyPaging<CaseResponseType>(),
	}),

	methods: {
		setColumns(columns: AppColumn<CaseResponseType>) {
			this.columns = columns;
		},
	},
});

export const AppOnlyAdminView = defineComponent({
	extends: AppPageBaseView,

	beforeMount() {
		if (!this.isUserAdmin) {
			this.$router.push({ name: 'not-authorized', query: { from: this.$route.fullPath } });
		}
	},
});
