import { StringMask } from '@/utils/StringMask';
import { NInput } from 'naive-ui';
import { defineComponent, ref } from 'vue';

const MAX_INPUT_LENGTH = 11;

export const AppInputCpf = defineComponent({
	name: 'AppInputCpf',
	emits: ['update:modelValue', 'update:value', 'change'],
	extends: NInput,

	props: {
		modelValue: String,
	},

	setup(props, { emit }) {
		const mask = '000.000.000-00';
		const fieldMask = new StringMask({ mask: mask });
		const maskedValue = ref(
			props.modelValue?.toString() ? fieldMask.apply(props.modelValue.toString()).maskedValue : '',
		);

		function handleInput(value: string) {
			value = value.toString();
			const response = fieldMask.apply(value);
			const result = response.maskedValue;
			maskedValue.value = result;

			if (response.unmaskedValue.length != 0) {
				emit('update:modelValue', result);
				emit('update:value', result);
			}
		}

		function onChange() {
			emit('change', maskedValue.value);
		}

		return () => (
			<NInput
				placeholder={mask}
				inputProps={{
					autofocus: true,
					maxlength: MAX_INPUT_LENGTH,
					autocomplete: 'cpf',
					inputmode: 'numeric',
				}}
				onChange={onChange}
				onInput={handleInput}
				value={maskedValue.value}
			/>
		);
	},
});

export default AppInputCpf;
