import { GeneralService } from '@/services';
import type { SelectBaseOption } from 'naive-ui/es/select/src/interface';
import { defineStore } from 'pinia';

export interface AppSettings {
	params: {
		baseAlmadaSetup: number;
		maxAlmadaSetup: number;
	};
	enums: {
		messageRoomTag: SelectBaseOption[];
		messageRoomType: SelectBaseOption[];
		messageStatus: SelectBaseOption[];
		caseStatus: SelectBaseOption[];
		brokerType: SelectBaseOption[];
	};
}

export const useAppStore = defineStore('appStore', {
	state: () => ({
		caseStatus: [] as SelectBaseOption[],
		tableHeight: 480,
		appState: false,
		appSettings: {
			params: {
				baseAlmadaSetup: 0,
				maxAlmadaSetup: 0,
			},
			enums: {
				messageRoomTag: new Array<SelectBaseOption>(),
				messageRoomType: new Array<SelectBaseOption>(),
				messageStatus: new Array<SelectBaseOption>(),
				caseStatus: new Array<SelectBaseOption>(),
				brokerType: new Array<SelectBaseOption>(),
			},
		},
	}),

	actions: {
		async loadCaseStatus() {
			const response = await await GeneralService.getEnumItems('CaseStatus');
			this.caseStatus = response.data;
		},

		setTableHeight() {
			this.tableHeight = window.innerHeight - 260;
		},

		setAppStatus(status: boolean) {
			this.appState = status;
		},

		async setAppSettings() {
			const response = await GeneralService.InitilizeSettings();
			this.appSettings = response;
		},
	},

	getters: {
		isMobile() {
			return window.innerWidth < 768;
		},
	},
});

export default useAppStore;
