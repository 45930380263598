import { StringMask } from '@/utils';
import { NInput } from 'naive-ui';
import { defineComponent, ref, type PropType } from 'vue';

const MAX_INPUT_LENGTH = 14;

export const AppInputCnpj = defineComponent({
	emits: ['update:modelValue', 'update:value'],

	props: {
		modelValue: String,
	},

	setup(props, { emit }) {
		const mask = '00.000.000/0000-00';
		const fieldMask = new StringMask({ mask: mask });
		const maskedValue = ref(
			props.modelValue ? fieldMask.apply(props.modelValue.toString()).maskedValue : '',
		);

		function handleInput(value: string) {
			const response = fieldMask.apply(value);
			const parsedValue = response.unmaskedValue;
			maskedValue.value = response.maskedValue;

			if (
				response.unmaskedValue.length === MAX_INPUT_LENGTH ||
				response.unmaskedValue.length == 0
			) {
				emit('update:modelValue', parsedValue);
				emit('update:value', parsedValue);
			}
		}

		return () => (
			<NInput
				placeholder={mask}
				inputProps={{
					maxlength: MAX_INPUT_LENGTH,
					autocomplete: 'cnpj',
					inputmode: 'numeric',
				}}
				onInput={handleInput}
				value={maskedValue.value}
			/>
		);
	},
});

export default AppInputCnpj;
