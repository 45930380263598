import type { INotification } from '@/interfaces';
import { HubsService, UserService } from '@/services';
import { UserStore } from '@/stores';
import { Colors, Icons, Routes } from '@/utils';
import { NBadge, NButton, NIcon } from 'naive-ui';
import { computed, defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';

export const AppUserNotification = defineComponent({
	name: 'AppUserNotification',
	setup() {
		const router = useRouter();
		const userStore = UserStore();

		userStore.initNofiicationsHub();

		const hasUnreadNotification = computed(() =>
			userStore.notifications.some((x) => x.readAt == null),
		);

		return () => (
			<NButton
				text
				class="p1"
				onClick={() => router.push({ name: Routes.NotificationsView })}
				secondary
			>
				<NBadge dot processing show={hasUnreadNotification.value}>
					<NIcon color={Colors.info} size="24" component={Icons.Notification} />
				</NBadge>
			</NButton>
		);
	},
});
