export enum GridifyFilter {
	Equal = '=',

	NotEqual = '!=',

	LessThan = '<',

	GreaterThan = '>',

	GreaterThanOrEqual = '>=',

	LessThanOrEqual = '<=',

	Contains = '=*',

	NotContains = '!*',

	StartsWith = '^',

	NotStartsWith = '!^',

	EndsWith = '$',

	NotEndsWith = '!$',

	In = '=',
}
