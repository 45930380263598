import { defineComponent, type PropType, ref, nextTick, computed } from 'vue';

import { PersonType } from '@/constants/PersonType';
import {
	NForm,
	NFormItem,
	NFormItemGi,
	NGrid,
	NInput,
	NRadio,
	NRadioGroup,
	NSelect,
	type FormInst,
} from 'naive-ui';
import { AppInputCnpj, AppInputCpf, AppInputDate, AppInputMoney } from '../Inputs';
import ClientFormSchema from '@/schemas/RenterFormSchema';
import ContactForm from './ContactForm';
import { PublicService } from '@/services/publicService';
import type { ICase } from '@/interfaces';
import type { ClientRequest } from '@/features/Clients/Client.dto';
import { defineForm } from './FormBase';

export interface IRenterForm {
	validate(): Promise<unknown>;
}

export const RenterForm = defineComponent({
	name: 'RenterForm',
	props: {
		showLegalPerson: {
			type: Boolean,
			default: true,
		},

		case: {
			type: Object as PropType<ICase>,
			default: null,
			required: false,
		},
	},

	extends: defineForm<ClientRequest>(),

	methods: {
		async searchRenter() {
			var response = await PublicService.getRenter(this.formData.document);
			if (response.data) {
				nextTick(() => {
					this.formData = response.data;
				});
			}
		},
	},

	computed: {
		blockDuplicates() {
			var _documentList = {
				documents: new Array<string>(),
				emails: new Array<string>(),
				phones: new Array<string>(),
			};

			if (this.case) {
				if (this.case.quotation.renterA != null) {
					_documentList.documents.push(this.case.quotation.renterA.document);
					_documentList.emails.push(this.case.quotation.renterA.contact.email);
					_documentList.phones.push(this.case.quotation.renterA.contact.phone);
				}

				if (this.case.quotation.renterB != null) {
					_documentList.documents.push(this.case.quotation.renterB.document);
					_documentList.emails.push(this.case.quotation.renterB.contact.email);
					_documentList.phones.push(this.case.quotation.renterB.contact.phone);
				}

				if (this.case.quotation.renterC != null) {
					_documentList.documents.push(this.case.quotation.renterC.document);
					_documentList.emails.push(this.case.quotation.renterC.contact.email);
					_documentList.phones.push(this.case.quotation.renterC.contact.phone);
				}

				if (this.case.quotation.renterD != null) {
					_documentList.documents.push(this.case.quotation.renterD.document);
					_documentList.emails.push(this.case.quotation.renterD.contact.email);
					_documentList.phones.push(this.case.quotation.renterD.contact.phone);
				}
			}

			return _documentList;
		},
	},

	render() {
		return (
			<NForm
				size="large"
				ref="formRef"
				{...{ attrs: this.$attrs }}
				label-placement="top"
				rules={ClientFormSchema(this.formData, this.blockDuplicates)}
				model={this.formData}
				onSubmit={this.handleSubmit}
			>
				<div class="mt-2">
					<NFormItem label="Tipo de pessoa" path="personType">
						<NRadioGroup v-model:value={this.formData.personType}>
							<NRadio label="Pessoa física" value={PersonType.Natural} />
							{this.showLegalPerson && (
								<NRadio label="Pessoa jurídica" value={PersonType.LegalPerson} />
							)}
						</NRadioGroup>
					</NFormItem>

					<NGrid cols="1 400:2" x-gap="4" y-gap="4">
						<NFormItemGi label="CPF" path="document">
							<AppInputCpf
								data-cy="input-document"
								onChange={this.searchRenter}
								v-model={this.formData.document}
							/>
						</NFormItemGi>

						<NFormItemGi label="Nome completo" path="name">
							<NInput
								data-cy="input-name"
								v-model:value={this.formData.name}
								placeholder="Nome Completo"
							/>
						</NFormItemGi>

						<NFormItemGi label="Data de nascimento" path="birthDate">
							<AppInputDate data-cy="input-birthDate" v-model={this.formData.birthDate} />
						</NFormItemGi>

						<NFormItemGi label="Renda declarada" path="declaredIncome">
							<AppInputMoney
								data-cy="input-declaredIncome"
								v-model={this.formData.declaredIncome}
							/>
						</NFormItemGi>

						<NFormItemGi label="Ocupação" path="occupation">
							<NSelect
								v-model:value={this.formData.occupation}
								options={[
									{ label: 'Assalariado', value: 'Assalariado' },
									{
										label: 'Autonomo/Profissional liberal',
										value: 'Autonomo/Profissional liberal',
									},
									{ label: 'Empresário', value: 'Empresário' },
									{ label: 'Aposentado', value: 'Aposentado' },
								]}
							/>
						</NFormItemGi>

						{this.formData.personType == PersonType.LegalPerson && [
							<NFormItemGi label="Data de abertura" path="openingDate">
								<AppInputDate data-cy="input-openingDate" v-model={this.formData.openingDate} />
							</NFormItemGi>,
							<NFormItemGi label="CNPJ" path="cnpj">
								<AppInputCnpj data-cy="input-cnpj" v-model={this.formData.cnpj} />
							</NFormItemGi>,
							<NFormItemGi label="Razão Social" path="fantasyName">
								<NInput
									data-cy="input-name"
									v-model:value={this.formData.fantasyName}
									placeholder="Razão Social"
								/>
							</NFormItemGi>,
						]}
					</NGrid>

					<ContactForm ref="formContactRef" v-model={this.formData.contact} />
				</div>
			</NForm>
		);
	},
});

export default RenterForm;
