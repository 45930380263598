import {
	NButton,
	NIcon,
	NImage,
	NLayoutHeader,
	NMenu,
	NSpace,
	NText,
	NConfigProvider,
	darkTheme,
} from 'naive-ui';
import { defineComponent, withKeys } from 'vue';

import logo from '@/assets/logo.svg';

import { Colors, Icons } from '@/utils';
import { AppUserMenu } from '..';
import { RenderIcon } from '../..';
import { headerMenuTheme } from '@/themes/global';
import { AppUserNotification } from '../AppUserNotification';
import { AppMenu } from './AppMenu';
import { AppHeaderSearchBar } from './AppHeaderSearchBar';
import { SuperChatButton } from '@/features/SuperChat/SuperChatButton';
import router from '@/router/router';

export const AppHeader = defineComponent({
	extends: AppMenu,

	render() {
		return (
			<NConfigProvider theme-overrides={darkTheme}>
				<NLayoutHeader>
					<NSpace align="center" justify="space-between" class="px2 py1">
						<div>
							<NImage src={logo} preview-disabled height="48" class="m0" />

							{process.env.NODE_ENV === 'development' && (
								<NText style="color: #ff0000">DEV ENV - {window.appVersion}</NText>
							)}
							{import.meta.env.VITE_APP_RELEASE_VERSION && (
								<NText style="font-size:10px; font-weight: bold; color: #a1a4a4">
									{import.meta.env.VITE_APP_RELEASE_VERSION}
								</NText>
							)}
						</div>
						<NSpace>
							<NMenu
								theme-overrides={headerMenuTheme}
								class="app-header-menu"
								v-model:value={this.activeKey}
								expandIcon={RenderIcon(Icons.ChevronDown)}
								collapsedIconSize={22}
								mode="horizontal"
								options={this.menuOptions}
							/>

							<AppHeaderSearchBar onSearch={this.handleSearch} />
						</NSpace>

						<NSpace align="center">
							<NButton text class="p1" secondary onClick={() => router.push({ name: 'faq' })}>
								<NIcon color={Colors.info} size="24" component={Icons.FAQ} />
							</NButton>
							<SuperChatButton />
							<AppUserNotification />
							<NButton text onClick={this.toggleTheme} secondary type="default" class="p1">
								<NIcon
									size="24"
									component={this.currentTheme == 'light' ? Icons.LightMode : Icons.DarkMode}
								/>
							</NButton>
							<AppUserMenu />
						</NSpace>
					</NSpace>
				</NLayoutHeader>
			</NConfigProvider>
		);
	},
});

export default AppHeader;
