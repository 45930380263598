import type { FormInst, FormItemRule, UploadInst } from 'naive-ui';
import { defineComponent, ref } from 'vue';

class defineFormSchemaOptions<TModel, TSchema> {
	startValue?: TModel | null = null;
	schema: TSchema | null = null;
}

export type TDefineForm = {
	validate(): Promise<boolean>;
};

export function defineForm<T, TSchema = null>(
	{ startValue, schema }: defineFormSchemaOptions<T, TSchema> = { startValue: null, schema: null },
) {
	return defineComponent({
		props: {
			modelValue: { type: Object },
			disabled: { type: Boolean, default: false },
		},

		data() {
			return {
				formData: ref<T>(Object.assign({}, this.modelValue || startValue) as T),
				supportParcelDate: '',
				formRef: ref<FormInst | null>(null),
				uploadRef: ref<UploadInst | null>(null),
			};
		},

		emits: ['update:modelValue', 'update:value', 'formValid', 'formInvalid'],

		computed: {
			requiredFields() {
				return Object.keys(this.formSchema as Object);
			},

			requiredFieldsQty() {
				return this.requiredFields.length;
			},

			fieldsFilled() {
				let filled = 0;
				let data = this.formData as { [field: string]: any };
				let schema = this.formSchema as { [field: string]: FormItemRule[] };

				// Check on formdata how many required fields is filled
				for (let field of this.requiredFields) {
					let value = data[field];
					if (value != null && value != undefined) {
						let shemaRules = schema[field];

						//@ts-ignore
						let validations = shemaRules
							.map((c) => c.validator(null, value))
							.filter((c) => c != true);

						console.log(field, validations);

						if (validations.length == 0) filled++;
					}
				}

				return filled;
			},

			formSchema(): TSchema {
				// if schema is a function, call it
				if (typeof schema === 'function') return schema(this.formData);

				// if schema is a object, return it
				if (schema) return schema;

				return {} as TSchema;
			},
		},

		methods: {
			formsRefs() {
				return this.$refs;
			},

			handleSubmit(e: Event | null) {
				e?.preventDefault();
				this.validate();
			},

			validate() {
				return new Promise(async (resolve, reject) => {
					const formRefs = this.formsRefs();

					let validationResults = [];

					for (const formRef of Object.keys(formRefs)) {
						try {
							const form = this.$refs[formRef] as FormInst;

							// check if validate method exists
							if (!form?.validate) continue;

							await form?.validate();
						} catch (err) {
							validationResults.push(err);
						}
					}

					validationResults = validationResults.flat().filter((c) => c != undefined);

					if (validationResults.length) {
						this.emitInvalidData();
						return reject(validationResults);
					}

					this.emitValidData();

					return resolve(true);
				});
			},

			emitValidData() {
				this.$emit('update:value', this.formData);
				this.$emit('update:modelValue', this.formData);
				this.$emit('formValid', true);
			},

			emitInvalidData() {
				this.$emit('formInvalid', false);
			},
		},
		watch: {
			modelValue: {
				immediate: true,
				handler(value) {
					if (value) this.formData = value;
				},
			},
		},
	});
}
