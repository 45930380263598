import type {
	IMessage,
	IMessageRoom as IMessageRoomResponse,
	IResponseBase,
	EntityTypes,
} from '@/interfaces';
import { Http, HttpService } from './http';

const http = Http.create();

export class MessageService extends HttpService {
	/**
	 * Send message based on entity id and type
	 * @param text
	 * @param entityId
	 * @param entityType
	 * @returns
	 */
	public static async sendMessage(text: string, entityId: string, entityType: string) {
		const response = await http.post<IResponseBase<boolean>>(`messages/${entityType}/${entityId}`, {
			text,
		});
		return response.data;
	}

	/**
	 * Get messages based on entity id and type
	 * @param entityId
	 * @param entityType
	 * @returns
	 */
	public static async getMessages(entityId: string, entityType: string) {
		const response = await http.get<IResponseBase<IMessage[]>>(
			`messages/${entityType}/${entityId}`,
		);
		return response.data;
	}

	public static async getMessageRoom(
		entityId: string,
		entityType: EntityTypes,
		useCancelToken = true,
	) {
		const cancelToken = this.createCancelToken('getMessageRoom');

		const response = await http.get<IResponseBase<IMessageRoomResponse>>(
			`message-rooms/${entityType}/${entityId}`,
			useCancelToken ? { cancelToken } : {},
		);
		return response.data;
	}

	public static async getMessageRooms(entityType: EntityTypes) {
		const response = await http.get<IResponseBase<IMessageRoomResponse[]>>(
			`message-rooms/${entityType}`,
		);
		return response.data;
	}

	public static async readRoom(entityId: string, entityType: EntityTypes) {
		const response = await http.patch(`message-rooms/${entityType}/${entityId}/read`);
		return response.data;
	}
}
