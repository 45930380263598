import { PersonType } from '@/constants';
import type { ClientRequest } from '@/features/Clients/Client.dto';
import { FormValidations } from '@/utils';

export function ClientFormSchema(
	client: ClientRequest,
	dups: { documents: string[]; emails: string[]; phones: string[] } = {
		documents: [],
		emails: [],
		phones: [],
	},
) {
	return {
		personType: [FormValidations.IsRequired],
		document: [
			FormValidations.IsRequired,
			FormValidations.IsCpfOrCnpj,
			FormValidations.NoDuplicates(dups.documents, 'Documento já cadastrado nesta proposta'),
		],
		name: [FormValidations.IsRequired, FormValidations.MinimalNameLength],
		occupation: [FormValidations.IsRequired],
		declaredIncome: [FormValidations.IsRequired, FormValidations.IsGreaterThanZero],
		birthDate: [
			FormValidations.IsRequired,
			FormValidations.IsDateValid,
			FormValidations.IsOver18YearsOld,
		],
		openingDate: [
			FormValidations.IsRequiredIf(client, 'personType', PersonType.LegalPerson),
			FormValidations.IsDateValid,
			FormValidations.IsMinimalOpeningDateValid,
		],
		cnpj: [
			FormValidations.IsRequiredIf(client, 'personType', PersonType.LegalPerson),
			FormValidations.IsCpfOrCnpj,
		],
		fantasyName: [
			FormValidations.IsRequiredIf(client, 'personType', PersonType.LegalPerson),
			FormValidations.IsRequired,
		],
	};
}

export function ClientRealEstateFormSchema(
	client: ClientRequest,
	dups: { documents: string[]; emails: string[]; phones: string[] } = {
		documents: [],
		emails: [],
		phones: [],
	},
) {
	return {
		...ClientFormSchema(client, dups),
		personType: [],
		maritalStatus: [FormValidations.IsRequired],
		nationality: [FormValidations.IsRequired],
	};
}

export default ClientFormSchema;
