import { StringMask } from "@/utils/StringMask";
import { NInput } from "naive-ui";
import { computed, defineComponent, ref, type PropType, watch } from "vue";

export const AppInputPhone = defineComponent({
    name: "AppInputCpf",
    emits: ["update:modelValue", "update:value"],
    extends: NInput,

    props: {
        modelValue: String,
        phoneType: { type: String as PropType<"tel" | "cel">, default: "cel" }
    },

    setup(props, { emit }) {
        const currentMask = computed(() => {
            return props.phoneType == 'cel' ? "(00) 00000-0000" : '(00) 0000-0000';
        })

        const fieldMask = new StringMask({ mask: currentMask.value });
        const maskedValue = ref(props.modelValue?.toString() ? fieldMask.apply(props.modelValue.toString()).maskedValue : "");

        watch(() => props.modelValue, (value) => handleInput(value));

        function handleInput(value: string | undefined) {
            if (!value) return;

            let mask = new StringMask({ mask: currentMask.value });
            value = value.toString();
            const response = mask.apply(value);
            const result = response.maskedValue;
            maskedValue.value = result;

            if (response.unmaskedValue.length != 0) {
                emit("update:modelValue", result);
                emit("update:value", result);
            }
        }

        return () => <NInput placeholder={currentMask.value} onInput={handleInput} value={maskedValue.value} />
    }
})

export default AppInputPhone;