import { computed, defineComponent } from 'vue';
import {
	darkTheme,
	NConfigProvider,
	NDialogProvider,
	NLayout,
	NLayoutContent,
	NMessageProvider,
	ptBR,
	datePtBR,
	NModalProvider,
} from 'naive-ui';
import { RouterView } from 'vue-router';

import { AppHeader, AppLogin } from './components/App';
import { useAppStore, UserStore } from './stores';
import { AuthService } from './services';
import { darkThemeOverrides } from './themes/dark';
import { lightThemeOverrides } from './themes/light';

import './services/amplitudeService';

import { AppMobileMenuBar } from './components/App/AppMenu/AppMobileMenuBar';
import { vMaska } from 'maska/vue';
import { LoadingZoo } from './components/App/LoadingZoo';

//@ts-ignore
export const WebAppAlmada = defineComponent({
	directives: { maska: vMaska },

	setup() {
		const userStore = UserStore();
		const appStore = useAppStore();

		const user = computed(() => {
			return userStore.user;
		});

		const theme = computed(() => {
			return userStore.appTheme == 'light' ? null : darkTheme;
		});

		const themeOverride = computed(() => {
			return userStore.appTheme == 'light' ? lightThemeOverrides : darkThemeOverrides;
		});

		const showLogin = computed(() => {
			return userStore.showLoginPrompt;
		});

		const appState = computed(() => {
			return appStore.appState;
		});

		appStore.setTableHeight();

		window.onresize = () => {
			useAppStore().setTableHeight();
		};

		return {
			locale: ptBR,
			dateLocale: datePtBR,
			user,
			theme,
			showLogin,
			themeOverride,
			appState,
			isMobile: appStore.isMobile,
		};
	},

	mounted() {
		const auth = new AuthService();
		auth.authorizeFlow();
	},

	render() {
		if (!this.appState)
			return (
				<div class="centered">
					<div class="acrylic-card">
						<LoadingZoo size={64} />
					</div>
				</div>
			);

		return (
			<NConfigProvider
				style="height: calc(100vh); display: flex; flex-direction: column;"
				theme={this.theme}
				locale={this.locale}
				dateLocale={this.dateLocale}
				theme-overrides={this.themeOverride}
			>
				<NModalProvider>
					<NDialogProvider>
						<NMessageProvider>
							{!this.showLogin && (
								<NLayout content-style="height: calc(100vh) !important; display: flex; flex-direction: column;">
									{!this.isMobile && <AppHeader />}
									<NLayoutContent id="app-base-layout" class="app-base-layout">
										<RouterView></RouterView>
									</NLayoutContent>

									{this.isMobile && <AppMobileMenuBar />}
								</NLayout>
							)}

							{this.showLogin && (
								<NLayout class="app-base-layout" position="absolute">
									<AppLogin />
								</NLayout>
							)}
						</NMessageProvider>
					</NDialogProvider>
				</NModalProvider>
			</NConfigProvider>
		);
	},
});

export default WebAppAlmada;
