import {
	NButton,
	NCard,
	NCarousel,
	NCarouselItem,
	NDrawer,
	NDrawerContent,
	NGi,
	NIcon,
	NImage,
	NLayoutHeader,
	NList,
	NListItem,
	NSpace,
	NText,
	NThing,
} from 'naive-ui';
import { defineComponent } from 'vue';
import { AppMenu } from './AppMenu';
import { Icons, Routes } from '@/utils';
import { AppMobileMenuItem } from './AppMobileMenuItem';
import { AppHeaderSearchBar } from './AppHeaderSearchBar';

export const AppMobileMenuBar = defineComponent({
	name: 'AppMobileMenuBar',
	extends: AppMenu,

	data: () => ({
		drawerState: false,
	}),

	methods: {
		handleDrawer(label: string) {
			this.drawerState = !this.drawerState;
		},
	},

	render() {
		return (
			<NLayoutHeader style="border-top: 1px solid #ebeef5;">
				<NSpace align="center" justify="space-between" class="pt1 px2">
					<AppMobileMenuItem label="Inicio" icon={Icons.Home} router={Routes.Home} />
					<AppMobileMenuItem label="Simulador" icon={Icons.Simulator} router={Routes.Simulator} />

					<div class="text-center">
						<div>
							<NImage previewDisabled src="../src/assets/logo-mobile.svg" width="48" />
						</div>
					</div>

					<AppMobileMenuItem label="Produtos" icon={Icons.Product} />
					<AppMobileMenuItem label="Todos" icon={Icons.MoreVert} onClicked={this.handleDrawer} />
				</NSpace>

				<NDrawer height="90vh" v-model:show={this.drawerState} placement="bottom">
					<NDrawerContent>
						{{
							header: () => (
								<div>
									<AppHeaderSearchBar
										onSearch={(query) => [this.handleSearch(query), this.handleDrawer('')]}
									/>
								</div>
							),

							default: () => (
								<div>
									{this.menuItems.map((item) => (
										<NList>
											<NListItem>
												<NThing title={item.name}></NThing>

												<NCarousel
													showDots={false}
													slides-per-view="auto"
													draggable
													loop={false}
													spaceBetween={20}
												>
													{item.children?.map((child) => (
														<NCarouselItem style="width: auto">
															<NCard>
																<AppMobileMenuItem
																	router={child.router}
																	label={child.name}
																	icon={child.icon}
																	onClicked={() => this.handleDrawer('')}
																/>
															</NCard>
														</NCarouselItem>
													))}
												</NCarousel>
											</NListItem>
										</NList>
									))}
								</div>
							),
						}}
					</NDrawerContent>
				</NDrawer>
			</NLayoutHeader>
		);
	},
});
