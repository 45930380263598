import type {
	IBroker,
	IBrokerProduct,
	IBrokerRequest,
	IGridifyPaging,
	IPayment,
	IResponseBase,
} from '@/interfaces';
import type { IGridifyQuery } from '@/models';
import { Http, HttpService } from './http';
import type { BrokerBillDTO, BrokerBillItemDTO } from '@/models/dto/BrokerBill.dto';
import type { CancelTokenSource } from 'axios';

const http = Http.create();

export class BrokerService extends HttpService {
	/**
	 * Return a list of brokers paginated based on query
	 * @param query
	 * @returns
	 */
	static async getBrokers(query: IGridifyQuery | null = null, cancelToken?: CancelTokenSource) {
		//const _cancelToken = cancelToken ?? this.createCancelToken('getBrokers');
		const response = await http.get<IGridifyPaging<IBroker>>('/brokers', {
			params: query,
			//cancelToken: _cancelToken,
		});

		this.cancelTokens['getBrokers'] = null;
		return response.data;
	}

	/**
	 * Add a new Broker
	 * @param brokerRequest
	 * @returns
	 */
	static async addBroker(brokerRequest: IBrokerRequest) {
		const response = await http.put<IResponseBase<IBroker>>('/brokers', brokerRequest);

		return response.data;
	}

	/**
	 * Get a broker by id
	 * @param query
	 * @returns
	 */
	static async getBroker(brokerId: string) {
		const response = await http.get<IResponseBase<IBroker>>(`/brokers/${brokerId}`);
		return response.data;
	}

	/**
	 * Update broker data
	 * @param broker
	 * @returns
	 */
	static async updateBroker(broker: IBroker) {
		const response = await http.post<IResponseBase<IBroker>>(`/brokers/${broker.id}`, broker);
		return response.data;
	}

	/**
	 * Return a list of broker products
	 * @param brokerId
	 * @returns
	 */
	static async getBrokerProducts(brokerId: string) {
		const cancelToken = this.createCancelToken('getBrokerProducts');

		const response = await http.get<IResponseBase<IBrokerProduct[]>>(
			`/brokers/${brokerId}/products`,
			{
				cancelToken,
			},
		);
		this.cancelTokens['getBrokerProducts'] = null;

		return response.data;
	}

	static async getBrokerBills(brokerId: string) {
		const cancelToken = this.createCancelToken('getBrokerBills');
		const response = await http.get<IResponseBase<BrokerBillDTO[]>>(`/brokers/${brokerId}/bills`, {
			cancelToken: cancelToken,
		});
		this.cancelTokens['getBrokerBills'] = null;
		return response.data;
	}

	static async getAllBrokerBills(brokerId: string, month: number, year: number) {
		const cancelToken = this.createCancelToken('getAllBrokerBills');
		const response = await http.get<IResponseBase<BrokerBillDTO[]>>(`/brokers/bills`, {
			params: {
				brokerId,
				month,
				year,
			},
			cancelToken: cancelToken,
		});
		this.cancelTokens['getAllBrokerBills'] = null;
		return response.data;
	}

	static async getBrokerBillItems(brokerId: string, month: number, year: number) {
		const cancelToken = this.createCancelToken('getBrokerBillItems');
		const response = await http.get<IResponseBase<BrokerBillItemDTO[]>>(
			`/brokers/${brokerId}/bills/${year}/${month}`,
			{ cancelToken: cancelToken },
		);
		this.cancelTokens['getBrokerBillItems'] = null;
		return response.data;
	}

	static async generateBrokerInvoice(brokerId: string, month: number, year: number) {
		const cancelToken = this.createCancelToken('generateBrokerInvoice');
		const response = await http.post<IResponseBase<IPayment>>(
			`/brokers/${brokerId}/bills/${year}/${month}/invoice`,
			null,
			{ cancelToken: cancelToken },
		);
		this.cancelTokens['generateBrokerInvoice'] = null;
		return response.data;
	}

	static async forceGenerateBrokerInvoice(brokerId: string, month: number, year: number) {
		const cancelToken = this.createCancelToken('generateBrokerInvoice');
		const response = await http.post<IResponseBase<IPayment>>(
			`/brokers/${brokerId}/bills/${year}/${month}/invoice/force`,
			null,
			{ cancelToken: cancelToken },
		);
		this.cancelTokens['generateBrokerInvoice'] = null;
		return response.data;
	}

	static GenerateBrokerInvoicePDF(brokerId: string, month: number, year: number) {
		return `${import.meta.env.VITE_API_SERVICE}/brokers/${brokerId}/invoices/${year}/${month}/pdf`;
	}

	static async createBrokerProduct(brokerId: string | string[], product: IBrokerProduct) {
		const cancelToken = this.createCancelToken('createBrokerProduct');
		const response = await http.post<IBrokerProduct>(`brokers/${brokerId}/products`, product, {
			cancelToken: cancelToken,
		});

		return response.data;
	}
}

export default BrokerService;
