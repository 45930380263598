import { MoneyHelper } from '@/utils';
import { NInputNumber } from 'naive-ui';
import { defineComponent, ref, watch } from 'vue';
import currency from 'currency.js';

export const AppInputMoney = defineComponent({
	props: {
		modelValue: Number,
		placeholder: {
			type: String,
			default: '0,00',
		},
		disabled: Boolean,
		readonly: Boolean,
	},

	emits: ['update:value', 'update:modelValue', 'blur'],

	setup(props, { emit }) {
		let refValue = ref<Number | undefined>();

		if (props.modelValue) {
			refValue.value = props.modelValue;
		}

		const handleInput = (value: any) => {
			const parsed = currency(value, { separator: '.', decimal: ',' });
			emit('update:value', parsed.value);
			emit('update:modelValue', parsed.value);
		};

		watch(
			() => props.modelValue,
			(value) =>
				value != null || value != undefined
					? (refValue.value = value)
					: (refValue.value = undefined),
		);

		return () => (
			<NInputNumber
				style="width: 100%"
				disabled={props.disabled}
				readonly={props.readonly}
				clearable
				placeholder={props.placeholder}
				v-model:value={refValue.value}
				onUpdateValue={handleInput}
				onBlur={(e) => emit('blur', e)}
				v-maska
				input-props={{
					//value: refValue.value,
					'data-maska': '9.99#,##',
					'data-maska-tokens': '9:[0-9]:repeated',
					'data-maska-reversed': true,
					inputmode: 'numeric',
				}}
				parse={MoneyHelper.toMachineFormat}
				format={MoneyHelper.toHumanFormat}
			>
				{{ prefix: () => 'R$' }}
			</NInputNumber>
		);
	},
});

export default AppInputMoney;
