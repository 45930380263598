import { AppMappingStates } from '@/components/App/AppPageBaseView';
import router from '@/router/router';
import { Icons, Routes } from '@/utils';
import Colors from '@/utils/colors';
import { NBadge, NButton, NIcon } from 'naive-ui';
import { defineComponent } from 'vue';

export const SuperChatButton = defineComponent({
	name: 'SuperChatButton',

	extends: AppMappingStates,

	computed: {},

	render() {
		if (!this.isBackOffice) return;

		return (
			<NButton text class="p1" secondary onClick={() => router.push({ name: 'customer-contanct' })}>
				<NBadge dot processing>
					<NIcon color={Colors.info} size="24" component={Icons.ChatMessage} />
				</NBadge>
			</NButton>
		);
	},
});
